import {
    Paginated,
    Sort,
    SortedPaginatedQueryParams,
} from "../sortedPaginated";
import { IUser, IUserActivity, IUserKeys } from "./user.types";
import { addCompanyIntercept, gatewayAuthErrorRedirect } from "../gateway";
import {
    queryCache,
    useMutation,
    QueryOptions,
    usePaginatedQuery,
    useQuery,
} from "react-query";
import { useMutationWrapper, useQueryWrapper } from "../reactQueryWrapper";

import { IGroup } from "./groups.types";
import { SelectOptionsPair } from "../../hooks/useEnhancedForm";
import axiosBase, { AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";

export const axios = axiosBase.create({
    baseURL: `${process.env.REACT_APP_API_ROOT}/services/netlifecoreservice/api`,
    withCredentials: true,
});
addCompanyIntercept(axios);
axios.interceptors.response.use((res) => res, gatewayAuthErrorRedirect);

interface Params extends SortedPaginatedQueryParams<IUser> {
    search?: string;
}

const USER_PARAMS: Params = {
    sorts: {},
    pageIndex: 0,
    pageSize: 20,
};

const sortedPaginated = (res?: AxiosResponse<IUser[]>) =>
    ({
        total: Number(res?.headers["x-total-count"] || res?.data?.length || 0),
        data: res?.data,
    } as Paginated<IUser>);

const prepareQuery = (params: Params) => {
    const param = {
        ...USER_PARAMS,
        ...params,
        sorts: {
            ...USER_PARAMS.sorts,
            ...params.sorts,
        },
    };
    const sorts = [...Object.entries(param.sorts), ["id", Sort.Ascending]]
        .filter((sort) => sort[1] != null)
        .map((sort) => "sort=" + sort.join(","))
        .join("&");

    return `page=${param.pageIndex}&size=${param.pageSize}&${sorts}${
        params.search != null && params.search.length > 0
            ? "&name.contains=" + encodeURIComponent(params.search)
            : ""
    }`;
};

const USERS_QUERY = "users";
const USERS_QUERY_EXPIRE_TIME = 500;

const USER_GROUPS_QUERY = "userGroups";
const USER_GROUPS_QUERY_EXPIRE_TIME = 500;
const userGroupsRequest = (_: any, id: string) =>
    axios.get<string[]>(`users/${id}/groups`);
export const useUserGroups = (id?: string) =>
    useQuery(id == null ? null : [USER_GROUPS_QUERY, id], userGroupsRequest, {
        retry: 1,
        staleTime: USER_GROUPS_QUERY_EXPIRE_TIME,
    });

const USER_QUERY = "user";
const USER_QUERY_EXPIRE_TIME = 20;
const userRequest = async (_: any, id: string) =>
    axios.get<IUser>(`users/${id}`);

export const useUser = (id?: string) =>
    useQuery(id == null ? null : [USER_QUERY, id], userRequest, {
        retry: 1,
        staleTime: USER_QUERY_EXPIRE_TIME,
    });

const userProfileRequest = async () => axios.get<IUser>(`me`);
export const useUserProfile = () =>
    useQuery(["me"], userProfileRequest, {
        retry: 1,
        staleTime: 1000 * 60 * 60 * 8, //cache for 8 hours
    });

export const useDeleteUsers = () => {
    const request = useCallback(
        (rows: number[]) =>
            axiosBase.all(rows.map((id) => deleteUserRequest({ id }))),
        []
    );
    return useMutationWrapper(request, { refetchQueries: [USERS_QUERY] });
};

const deleteUserRequest = (user: { id: number }) =>
    axios.delete(`users/${user.id}`);

const usersRequest = () => axios.get<IUser[]>("users");
export const useUsers = () =>
    useQuery(USERS_QUERY, usersRequest, {
        retry: 1,
        staleTime: USERS_QUERY_EXPIRE_TIME,
    });

const allUsersExportRequest = () => axios.get<IUser[]>("all-users-export");
export const useAllUsersExport = () =>
    useQuery(USERS_QUERY, allUsersExportRequest, {
        retry: 1,
        staleTime: 1000000,
    });

const notifyUserRequest = () => {
    return axios.get<IUser[]>("users").then((res) => {
        return res.data;
    });
};

export const useNotifyUserOptions = (companyFix: any) =>
    useQuery(["notifyUserQuery", companyFix], notifyUserRequest, {
        staleTime: USERS_QUERY_EXPIRE_TIME,
    });

const companyBasedUsersRequest = (_: any, id: any) => {
    return axios.get<IUser[]>("company-based-users/" + id).then((res) => {
        return res.data;
    });
};

export const useCompanyBasedUsers = (id: any) =>
    useQuery(["companyBasedUsers", id], companyBasedUsersRequest, {
        staleTime: 1000000,
    });

export const useAllUsers = (
    params = USER_PARAMS,
    options?: QueryOptions<Paginated<IUser>>
) => {
    const query = useMemo(() => prepareQuery(params), [params]);
    const request = useCallback(
        () => axios.get<IUser[]>(`users?${query}`).then(sortedPaginated),
        [query]
    );
    return useQueryWrapper<Paginated<IUser>, any>(
        [USERS_QUERY, { query }],
        request,
        {
            ...options,
            retry: 1,
            staleTime: USERS_QUERY_EXPIRE_TIME,
        }
    );
};

const USERS_ACTIVITY_QUERY = "/users/activities";
const USERS_ACTIVITY_QUERY_EXPIRE_TIME = 500;
const usersActivitiesRequest = (_: any, params: any) =>
    axios.get<IUserActivity[]>(
        `${USERS_ACTIVITY_QUERY}?from=${params.fromDate}&to=${params.toDate}`
    );

export const useUsersActivities = (params: any) => {
    let paramsObj = {
        fromDate: "null",
        toDate: "null",
    };
    if (params.fromDate !== null && params.toDate !== null) {
        let fromDate = new Date(params.fromDate);
        let rawFromDtStr = fromDate.toLocaleDateString().split("/");
        let fromMonth = rawFromDtStr[0];
        let fromDay = rawFromDtStr[1];
        let fromYear = rawFromDtStr[2];

        if (Number(fromMonth) < 10) {
            fromMonth = "-0" + fromMonth;
        } else {
            fromMonth = "-" + fromMonth;
        }

        if (Number(fromDay) < 10) {
            fromDay = "-0" + fromDay;
        } else {
            fromDay = "-" + fromDay;
        }

        let filterFromDate = new Date(fromYear + fromMonth + fromDay);
        let fromDateStr = filterFromDate.toISOString();

        let toDate = new Date(params.toDate);
        let rawToDtStr = toDate.toLocaleDateString().split("/");
        let toMonth = rawToDtStr[0];
        let toDay = rawToDtStr[1];
        let toYear = rawToDtStr[2];

        if (Number(toMonth) < 10) {
            toMonth = "-0" + toMonth;
        } else {
            toMonth = "-" + toMonth;
        }

        if (Number(toDay) < 10) {
            toDay = "-0" + toDay;
        } else {
            toDay = "-" + toDay;
        }

        let filterToDate = new Date(toYear + toMonth + toDay);
        let toDateStr = filterToDate.toISOString();

        paramsObj.fromDate = fromDateStr;
        paramsObj.toDate = toDateStr;
    }

    return useQuery([USERS_ACTIVITY_QUERY, paramsObj], usersActivitiesRequest, {
        retry: 2,
        staleTime: USERS_ACTIVITY_QUERY_EXPIRE_TIME,
    });
};

export const usePaginatedUsers = () =>
    usePaginatedQuery(USERS_QUERY, usersRequest, {
        retry: 1,
        staleTime: USERS_QUERY_EXPIRE_TIME,
    });

const FIELDS_QUERY = "userFields";
const FIELDS_EXPIRE = 2000;
const fieldEndpoints: [IUserKeys, string][] = [
    ["company", "companies?page=0&size=100000"],
    ["groups", "groups"],
    ["roles", "roles"],
    ["type", "user-types"],
    ["otherAssociatedCompanies", "companies?page=0&size=100000"],
    ["country", "countries"],
    ["notifyUsers", "users"],
];
const fieldsRequest = () =>
    axiosBase.all<SelectOptionsPair<IUser>>(
        fieldEndpoints.map(([field, endpoint]) =>
            axios
                .get(endpoint)
                .then((x) => [
                    field,
                    field === "groups"
                        ? x.data.filter(
                              (group: IGroup) =>
                                  !group.path?.startsWith("/company-")
                          )
                        : x.data,
                ])
        )
    );
export const useUserNestedFields = (editing: boolean) =>
    useQueryWrapper(editing ? FIELDS_QUERY : null, fieldsRequest, {
        staleTime: FIELDS_EXPIRE,
    });

const updateRequest = ({ data, create }: { data: IUser; create: boolean }) =>
    axios.request({
        url: create ? "/users" : "/users/" + data.id,
        method: create ? "POST" : "PUT",
        data,
    });
export const useUpdateUser = () =>
    useMutation(updateRequest, {
        onSuccess: (_, { data }) => {
            queryCache.refetchQueries(USERS_QUERY);
            if (data?.id != null)
                queryCache.setQueryData([USER_QUERY, data.id], data);
        },
        useErrorBoundary: false,
        throwOnError: true,
    });

const importUserRequest = ({
    isActive,
    fileJsonData,
}: {
    isActive: boolean;
    fileJsonData: Array<any>;
}) => {
    let source = axiosBase.CancelToken.source();
    setTimeout(() => {
        source.cancel();
    }, 300000);
    return axios.post(`/users-import?isActive=${isActive}`, fileJsonData, {
        headers: {
            "Content-Type": "application/json",
        },
        cancelToken: source.token,
    });
};

export const assignRolestoUsers = (payload: any) => {
    let source = axiosBase.CancelToken.source();
    setTimeout(() => {
        source.cancel();
    }, 300000);
    return axios.post(`/assign-roles-to-users`, payload, {
        headers: {
            "Content-Type": "application/json",
        },
        cancelToken: source.token,
    });
};

export const useImportUser = () =>
    useMutation(importUserRequest, {
        onSuccess: () => {
            queryCache.refetchQueries(USERS_QUERY);
        },
    });

export default axios;
